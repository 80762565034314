import { render, staticRenderFns } from "./CmsTail.vue?vue&type=template&id=cca58bfe&scoped=true&"
import script from "./CmsTail.vue?vue&type=script&lang=js&"
export * from "./CmsTail.vue?vue&type=script&lang=js&"
import style0 from "./CmsTail.vue?vue&type=style&index=0&id=cca58bfe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cca58bfe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediClubLogo: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogo.vue').default,BaseHeading: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,BaseImage: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseChips: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseChips.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default,BaseCarousel: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/carousel/BaseCarousel.vue').default})
